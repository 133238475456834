import React, { useState } from 'react'
import { useQuery } from 'react-query';
import { getUsers } from '../../services/Users';
import { FaSearch } from 'react-icons/fa';
import Input from '../../components/input';
import UsersTable from '../../components/usersTable';
import 'react-toastify/dist/ReactToastify.css';
import { useOutletContext } from 'react-router-dom';

export default function Dashboard() {
  const [search, setSearch] = useState('');
  const [darkMode] = useOutletContext();
  const {data: users, isLoading} = useQuery({
    queryKey: ['search', search],
    queryFn: () => {
      if (search) return getUsers(search);
      return [];
    },
    refetchOnWindowFocus: false,
  });
    
  const handleChange = (e) => {
    setSearch(e.target.value);
  }

  return (
      <div className='flex flex-col gap-5'>
        <div className={`${darkMode && 'bg-gray-900 bg-opacity-70'} p-5 border-2 rounded-xl`}>
          <p>Cari berdasarkan nama / email</p>
          <Input
            icon={<FaSearch style={{ height: '100%' }} />}
            placeholder={'Search ...'}
            onChange={handleChange}
            value={search}
            key={'search'}
            darkMode={darkMode}
          />
        </div>
        <div className={`${darkMode && 'bg-gray-900 bg-opacity-70'} p-5 border-2 rounded-xl`}>
          <UsersTable data={users ?? []} isLoading={isLoading} />
        </div>
      </div>
  )
}
