import React from 'react'
import { FaMoon, FaSignOutAlt, FaSun, FaWhatsapp } from 'react-icons/fa'
import auth from '../../config/firebase';

export default function Header({darkMode, setDarkMode}) {
  const logout = async () => {
    try {
      console.log(await auth.signOut());
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className='border-b-2 py-5 flex justify-center'>
        <div className='w-full xl:w-11/12 px-5 xl:px-0'>
            <div className='flex justify-between items-center'>
                <span className='font-bold text-lg dark:text-white'>
                    Admin Google
                </span>
                <ul className='flex gap-4 items-center dark:text-white'>
                    <li>
                        <a 
                            href="https://api.whatsapp.com/send/?phone=628152100999&text=%23GCR%0D%0ASaya+...+dari+...+memiliki+kendala+...&type=phone_number&app_absent=0"
                            className='flex items-center gap-2 border-2 px-5 py-1.5 rounded-lg'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <span className='text-md font-extrabold'>Helpdesk GCR</span>
                            <FaWhatsapp className='text-xl' />
                        </a>
                    </li>
                    <li>
                        <button className='flex items-center text-xl' onClick={() => setDarkMode(!darkMode)}>
                            {darkMode ? <FaSun /> : <FaMoon />}
                        </button>
                    </li>
                    <li>
                        <button className='flex items-center text-xl' onClick={logout}>
                            <FaSignOutAlt />
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
  )
}
