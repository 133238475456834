import React from 'react'
import { useMutation } from 'react-query';
import { ClipLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { toast } from 'react-toastify';
import { deleteLicense } from '../../services/License';
import { FaCheck } from 'react-icons/fa';

export default function DeleteLicenseButton({ email, refetch }) {
  const MySwal = withReactContent(Swal);
  
  const deleteLicenseMutation = useMutation({
    mutationKey: ["license", email],
    mutationFn: (email) => deleteLicense(email),
  });

  const handleClick = () => {
    MySwal.fire({
      title: 'Are you sure?',
      text: `Delete license for ${email}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteLicenseMutation.mutate(email, {
          onSuccess: (data) => {
            if (data) {
              toast.success(`License for ${email} has been deleted`);
              refetch();
            } else {
              toast.error('License delete failed');
            }
          },
          onError: (err) => {
            toast.error('License delete failed');
          },
        });
      }
    });
  }

  return (
    <button
      className="rounded-full"
      disabled={deleteLicenseMutation.isLoading}
      onClick={handleClick}
    >
      <div 
        className={`flex items-center py-2 px-2 rounded-lg gap-1 
        ${deleteLicenseMutation.isLoading ? 'bg-gray-300 text-black' : 'bg-green-600 text-white'}`}
      >
        {deleteLicenseMutation.isLoading && <ClipLoader size={15} />}
        {deleteLicenseMutation.isLoading ? 'Loading' : (
          <span className='flex items-center gap-2'>
            <FaCheck />
            {/* <span className='hidden lg:block'>Delete License</span> */}
          </span>
        )}
      </div>
    </button>
  )
}
