import { createBrowserRouter } from "react-router-dom";
import Dashboard from "../pages/dashboard";
import Account from "../pages/account";
import Login from "../pages/login";
import Layout from "../layouts";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    element: <Layout />,
    children: [
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/account",
        element: <Account />,
      },
    ],
  },
]);

export default router;