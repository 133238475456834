import axios from "axios";
import BASEURI from "../config/baseUri.json";
import auth from "../config/firebase";

const axiosInstance = axios.create({
  baseURL: `${BASEURI.API_GCR_BASE_URI}/penggunaanakun/`,
  retry: 1,
});

export const assignLicense = async (email) => {
  const bearerToken = await auth?.currentUser?.accessToken;
  axiosInstance.defaults.headers.Authorization = `Bearer ${bearerToken}`;
  const response = await axiosInstance.post(`assign-license`, {
    email,
  });
  const result = await response.data;
  const success = !result.gagal;
  return success;
};

export const deleteLicense = async (email) => {
  const bearerToken = await auth?.currentUser?.accessToken;
  axiosInstance.defaults.headers.Authorization = `Bearer ${bearerToken}`;
  const response = await axiosInstance.post(`delete-license`, {
    email,
  });
  const result = await response.data;
  const success = !result.gagal;
  return success;
};