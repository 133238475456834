import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";

import React, { useEffect, useRef, useState } from "react";
import Pagination from "./pagination";
import Filter from "./filter";
import { ClipLoader } from "react-spinners";
import ChangeOuButton from "../changeOuButton";
import { useOutletContext } from "react-router-dom";
import { FaChevronCircleDown, FaChevronCircleUp } from "react-icons/fa";
import DeleteLicenseButton from "../deleteLicenseButton";
import AssignLicenseButton from "../assignLicenseButton";
import DeleteUserButton from "../deleteUserButton";

export default function AccountTable({ 
  accounts = [],
  isLoading,
  totalDocs: totalEntries,
  totalPages = 1,
  currentPage = 1,
  setPage,
  limit,
  setLimit,
  refetch,
  sorting,
  setSorting,
})
{
  const [globalFilter, setGlobalFilter] = useState("");
  const columnHelper = createColumnHelper();
  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState([]);
  const [darkMode] = useOutletContext();

  useEffect(() => {
    setSelectedAccount([]);
    setChecked(false);
    setIndeterminate(false);
  }, [accounts]);

  function toggleAll() {
    setSelectedAccount(checked || indeterminate ? [] : accounts.map((p) => p.primaryEmail));
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  const columns = [
    columnHelper.accessor("No", {
      cell: (info) => (
        <div className="flex gap-1.5 justify-center">
          <input
            type="checkbox"
            className="w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
            value={info.row.original.id}
            checked={selectedAccount.includes(info.row.original.primaryEmail)}
            onChange={(e) => {
              setSelectedAccount(
                e.target.checked
                  ? [...selectedAccount, info.row.original.primaryEmail]
                  : selectedAccount.filter((p) => p !== info.row.original.primaryEmail)
              );
            }}
          />
          <span>{(currentPage - 1) * limit + parseInt(info.row.id) + 1}</span>
        </div>
      ),
      header: () => (
        <div className="flex gap-1.5 justify-center ps-2">
          <input
            type="checkbox"
            className="w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
            ref={checkbox}
            checked={checked}
            onChange={toggleAll}
          />
          <span>No</span>
        </div>
      ),
    }),
    columnHelper.accessor("Nomor Induk", {
      cell: (info) => {
        return (
        <div className="px-2 py-1 text-center">
          { info.row.original?.nomorInduk || '-' }
        </div>
      )}
    }),
    columnHelper.accessor((row) => row.fullName, {
      id: "fullname",
      cell: (info) => <div className="px-2 py-1">{info.getValue()}</div>,
      header: () => <div className="py-1">Nama</div>,
    }),
    columnHelper.accessor((row) => row.primaryEmail, {
      id: "email",
      cell: (info) => <div className="px-2 py-1">{info.getValue()}</div>,
      header: () => <div className="py-1">Email</div>,
    }),
    columnHelper.accessor((row) => row.lastLoginTime, {
      id: "lastLoginTime",
      cell: (info) => <div className="px-2 py-1 text-center">{new Date(info.getValue()).toISOString().substring(0, 10)}</div>,
      header: () => <div className="py-1">Terakhir Login</div>,
    }),
    columnHelper.accessor((row) => row.creationTime, {
      id: "creationTime",
      cell: (info) => <div className="px-2 py-1 text-center">{new Date(info.getValue()).toISOString().substring(0, 10)}</div>,
      header: () => <div className="py-1">Created At</div>,
    }),
    columnHelper.accessor((row) => row.orgUnitPath, {
      id: "ou",
      cell: (info) => <div className="px-2 py-1 text-center">{info.getValue()}</div>,
      header: () => <div className="py-1">OU</div>,
    }),
    columnHelper.accessor((row) => row.storageLimit, {
      id: "storage-limit",
      cell: (info) => <div className="px-2 py-1 text-center">{info.getValue() === -1 ? <span>&infin;</span> : info.getValue()}</div>,
      header: () => <div className="py-1">Storage Limit (GB)</div>,
    }),
    columnHelper.accessor((row) => row.storageUsed, {
      id: "storage-used",
      cell: (info) => <div className="px-2 py-1 text-center">{info.getValue()}</div>,
      header: () => <div className="py-1">Storage Used (GB)</div>,
    }),
    columnHelper.accessor("Lisensi Plus", {
      cell: (info) => (
        <div className="flex justify-center">
          {
            info.row.original.skuId ? (
              <div className="mx-auto">
                <DeleteLicenseButton email={info.row.original.primaryEmail} refetch={refetch} />
              </div>
            ) : (
              <div className="mx-auto">
                <AssignLicenseButton email={info.row.original.primaryEmail} refetch={refetch} />
              </div>
            )
          }
        </div>
      ),
    }),
    columnHelper.accessor("Action", {
      cell: (info) => {
        return (
        <div className="flex gap-2 justify-center my-1.5">
          {/* <ResetButton email={info.row.original.primaryEmail} /> */}
          <ChangeOuButton emails={[info.row.original.primaryEmail]} refetch={refetch} />
          {/* {info.row.original.skuId ? <DeleteLicenseButton email={info.row.original.primaryEmail} refetch={refetch} /> : <AssignLicenseButton email={info.row.original.primaryEmail} refetch={refetch} />} */}
          <DeleteUserButton email={[info.row.original.primaryEmail]} refetch={refetch} />
        </div>)
      },
    }),
  ];

  const table = useReactTable({
    data: accounts,
    columns,
    state: {
      globalFilter,
      sorting,
    },
    enableSorting: true,
    manualSorting: true,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  return (
    <div className="space-y-2 overflow-auto">
      <ChangeOuButton
        emails={selectedAccount}
        refetch={refetch}
        title={`Change OU for ${selectedAccount.length} account${selectedAccount.length > 1 ? 's' : ''}`}
      />
      <Filter
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        limit={limit}
        setLimit={setLimit}
      />
      <table className="table-auto w-[100%] border-collapse overflow-x-auto">
        <thead 
          className={`border-collapse border-b
            ${darkMode ? 'bg-gray-900 bg-opacity-70 border-gray-500' : 'border-black'}
          `}
        >
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, index) => (
                <th key={header.id} className="py-2 px-1">
                  {header.isPlaceholder
                    ? null
                    : <div
                        onClick={header.id === 'storage-used' ? header.column.getToggleSortingHandler() : undefined} 
                        className={`${header.id === 'storage-used' && "select-none cursor-pointer flex justify-center items-center gap-1"}`}>
                        {
                          flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )
                        }
                        {header.id === 'storage-used' && (
                          <div className='ms-1 text-xs'>
                            {
                              !sorting[0]?.id ? 
                                <div className="space-y-1">
                                  <FaChevronCircleUp className="text-gray-500" />
                                  <FaChevronCircleDown className="text-gray-500" />
                                </div>
                              :
                                (
                                  <div className="space-y-1 text-white">
                                    <FaChevronCircleUp className={`${sorting[0]?.id === 'storage-used' && sorting[0]?.desc && 'text-gray-500'}`} />
                                    <FaChevronCircleDown className={`${sorting[0]?.id === 'storage-used' && !sorting[0]?.desc && 'text-gray-500'}`} />
                                  </div>
                                )
                            }
                          </div>
                        )}
                      </div>
                  }
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {isLoading ? (
            <tr className={`border-collapse border-b ${darkMode ? 'border-gray-500' : 'border-black'}`}>
              <td className="text-center" colSpan={columns.length}>
              <div className="flex justify-center py-2 items-center">
                <ClipLoader color={darkMode ? 'white' : 'black'} />
              </div>
              </td>
            </tr>
          ) : table.getRowModel().rows.length > 0 ? (
            table.getRowModel().rows.map((row, index) => (
              <tr 
                key={row.id} 
                className={`border-collapse border-b
                  ${darkMode ? 
                    ('bg-gray-900 bg-opacity-70 border-gray-500') : 
                    (index % 2 === 1 ? 'bg-white' : 'bg-gray-200') + ' border-black'
                  }`
                }
              >
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr className={`border-collapse border-b ${darkMode ? 'border-gray-500' : 'border-black'}`}>
              <td className="text-center py-2" colSpan={columns.length}>
                Tidak ada data
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Pagination
        onPageChange={page => {
          setPage(page);
        }}
        totalPage={totalPages}
        siblingCount={1}
        currentPage={currentPage}
        pageSize={table.getState().pagination.pageSize}
        totalEntries={totalEntries}
        limit={limit}
      />
    </div>
  );
}
