import React, { useEffect } from 'react'
import Input from '../../components/input'
import { FaAt, FaKey } from 'react-icons/fa'
import { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import auth from '../../config/firebase';
import { ClipLoader } from 'react-spinners';
import loginImg from '../../assets/login.png';
import untan from '../../assets/untan.png';
import { toast } from 'react-toastify';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordType, setPasswordType] = useState('password');
  const [isLoading, setIsLoading] = useState(false);
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      if (user.email === 'imamav@untan.ac.id') {
        navigate('/account');
      } else {
        navigate('/dashboard');
      }
    }
  }, [user, loading, navigate]);

  if (loading) {
    return (
      <div>
        <h1>Loading...</h1>
      </div>
    );
  }

  const showPassword = () => {
    if (passwordType === 'password') {
      return setPasswordType('text');
    }
    return setPasswordType('password');
  }

  const login = async () => {
    try {
      setIsLoading(true);
      await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
      toast.error('Wrong email or password');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='w-screen h-screen flex justify-center'>
      <div className='h-full w-3/4 flex flex-col justify-between lg:justify-evenly p-5 lg:p-0'>
        <div className="flex gap-2 items">
          <img src={untan} alt="Logo Untan" style={{ height: 50 }} />
          <div className='flex flex-col justify-center text-sm'>
            <span className='font-bold text-md'>Universitas Tanjungpura</span>
            <span>Pontianak - Kalimantan Barat</span>
          </div>
        </div>
        <div className='w-full px-5 lg:px-0'>
          <div className='flex flex-col items-center lg:flex-row gap-0 lg:gap-8 xl:gap-16'>
            <img src={loginImg} alt="" className='2xl:max-w-[600px] lg:max-w-md md:max-w-sm mb-0 md:mb-4'/>
            <div className='flex-1 space-y-4 border-2 p-5 lg:px-10 xl:p-16 rounded-3xl'>
              <h4 className='text-center font-extrabold text-2xl'>Google Classroom</h4>
              <p className='font-bold'>Halaman Login</p>
              <span>Anda belum masuk. Silahkan masuk menggunakan akun SIAKAD anda.</span>
              <Input
                icon={<FaAt style={{ height: "100%" }} />}
                name={'email'}
                placeholder={'Email Address'}
                key={'email'}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Input
                icon={<FaKey style={{ height: "100%" }} />}
                name={'password'}
                placeholder={'Password'}
                key={'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type={passwordType}
                handleShowPassword={showPassword}
              />
              <button
                onClick={login}
                disabled={isLoading}
                className={`rounded-lg ${isLoading ? 'bg-gray-400 text-black' : 'bg-blue-500 text-white'} flex items-center text-md px-20 py-3 gap-2 font-semibold`}
              >
                {isLoading && <ClipLoader size={15} color='white' />}
                Login
              </button>
            </div>
          </div>
        </div>
        <div className='text-sm text-center'>Dikembangkan oleh <span className='font-bold'>UPT. Teknologi Informasi dan Komunikasi</span> Universitas Tanjungpura</div>
      </div>
    </div>
  )
}
