import React, { useState } from 'react'
import { useMutation } from 'react-query';
import { changeOu } from '../../services/Ou';
import { ClipLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { FiRefreshCw } from 'react-icons/fi';
import { toast } from 'react-toastify';

export default function ChangeOuButton({ title, emails, refetch }) {
  const [ou, setOu] = useState(null);
  const MySwal = withReactContent(Swal);

  const changeOuMutation = useMutation({
    mutationKey: ["email", emails, "ou", ou],
    mutationFn: async ({ emails, ou }) => {
      for await (const email of emails) {
        await changeOu(email, ou);
      }
      return true;
    },
  });

  const handleClick = () => {
    MySwal.fire({
      title: 'Select Organizational Unit',
      input: 'select',
      inputOptions: {
        '/': 'untan.ac.id',
        '/Alumni': 'Alumni',
        '/Karantina': 'Karantina',
        '/Organisasi Mahasiswa': 'Organisasi Mahasiswa',
        '/Staff': 'Staff',
        '/Staff/Ekonomi': 'Ekonomi',
        '/Staff/Fahutan': 'Fahutan',
        '/Staff/Faperta': 'Faperta',
        '/Staff/faperta.untan.ac.id': 'faperta.untan.ac.id',
        '/Staff/Fisip': 'Fisip',
        '/Staff/Fkip': 'Fkip',
        '/Staff/Fmipa': 'Fmipa',
        '/Staff/Fmipa/fmipa.untan.ac.id': 'fmipa.untan.ac.id',
        '/Staff/Fmipa/fmipa.untan.ac.id/siskom.untan.ac.id': 'siskom.untan.ac.id',
        '/Staff/Hukum': 'Hukum',
        '/Staff/Kedokteran': 'Kedokteran',
        '/Staff/Kepegawaian': 'Kepegawaian',
        '/Staff/Rumah Sakit': 'Rumah Sakit',
        '/Staff/Teknik': 'Teknik',
        '/Staff/Teknik/Informatika': 'Informatika',
        '/Staff/Teknik/teknik.untan.ac.id': 'teknik.untan.ac.id',
        '/Staff/Teknik/teknik.untan.ac.id/civil.untan.ac.id': 'civil.untan.ac.id',
        '/Staff/Teknik/teknik.untan.ac.id/ee.untan.ac.id': 'ee.untan.ac.id',
        '/student.untan.ac.id': 'student.untan.ac.id',
        '/UPT. TIK': 'UPT. TIK'
      },
      inputPlaceholder: 'Select Organizational Unit',
      showCancelButton: true,
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
      inputValidator: (value) => {
        if (!value) {
          return 'You need to select Organizational Unit!';
        }
        setOu(value);
      }
    }).then((result) => {
      if (result.isConfirmed) {
        changeOuMutation.mutate({ emails, ou: result.value }, {
          onSuccess: (data) => {
            if (data) {
              toast.success(`OU for ${emails.join(', ')} has been changed to ${result.value}`);
              refetch();
            } else {
              toast.error('OU change failed');
            }
          },
          onError: (err) => {
            toast.error('OU change failed');
          },
        });
      }
    });
  }

  return (
    <button
      className="rounded-full"
      disabled={changeOuMutation.isLoading}
      onClick={handleClick}
    >
      <div
        className={`flex items-center py-2 3xl:py-1 px-3 rounded-lg gap-1 
        ${changeOuMutation.isLoading ? 'bg-gray-300 text-black' : 'bg-green-600 text-white'}`}
      >
        {changeOuMutation.isLoading && <ClipLoader size={15} />}
        {changeOuMutation.isLoading ? 'Loading' : (
          <span className='flex items-center gap-2'>
            <FiRefreshCw />
            {title || <span className='hidden 3xl:block'>Change OU</span>}
          </span>
        )}
      </div>
    </button>
  )
}
