import axios from "axios";
import BASEURI from "../config/baseUri.json";
import auth from "../config/firebase";

const axiosInstance = axios.create({
  baseURL: `${BASEURI.API_GCR_BASE_URI}/account/`,
  headers: {
    "Content-Type": "application/json",
  },
  retry: 1,
});

export const listUsers = async ({
    page = 1,
    limit = 10,
    email = "",
    name = "",
    license = null,
    lastLoginTime = "",
    lastLoginOperator = "$lt",
    creationTime = "",
    creationOperator = "$lt",
    ou = "",
    sizeOperator = "",
    size = "",
    sizeSortDesc,
}) => {
  const bearerToken = await auth?.currentUser?.accessToken;
  axiosInstance.defaults.headers.Authorization = `Bearer ${bearerToken}`;
  const response = await axiosInstance.get(`?page=${page}&limit=${limit}&email=${email}&name=${name}&lastLoginTime=${lastLoginTime}&lastLoginOperator=${lastLoginOperator}&creationTime=${creationTime}&creationOperator=${creationOperator}&ou=${ou}&limit=${limit}&plus=${license}&sizeOperator=${sizeOperator}&size=${size}&sizeSort=${sizeSortDesc === undefined ? '' : sizeSortDesc ? 'desc': 'asc'}&version=${new Date().getTime()}`);
  const result = await response.data;
  return result;
};

export const deleteUser = async (email) => {
  const bearerToken = await auth?.currentUser?.accessToken;
  axiosInstance.defaults.headers.Authorization = `Bearer ${bearerToken}`;
  const response = await axiosInstance.delete(`?email=${email}`);
  const result = await response.data;
  return result;
}
