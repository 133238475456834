import React from 'react'
import { FaEye, FaEyeSlash } from 'react-icons/fa'

export default function Input({ icon, onChange, value, placeholder, name, title, type = 'text', handleShowPassword, className = '', darkMode }) {
  return (
    <div className='flex flex-col space-y-1 text-black'>
      <label htmlFor={name} className={`capitalize ${darkMode && 'text-white'}`}>{title}</label>
      <div className={`flex border rounded-lg ${className}`}>
        <span className='border-e px-3 bg-white rounded-s-lg'>{icon}</span>
        <input
          type={type}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          id={name}
          name={name}
          className='p-2 rounded-e-md flex-1 focus:outline-none'
        />
        {name === 'password' && (
          <button className='px-3' onClick={handleShowPassword}>
            { type === 'password' ? <FaEye style={{ height: '100%' }}/> : <FaEyeSlash style={{ height: '100%' }}/>}
          </button>
        )}
      </div>
    </div>
  )
}
