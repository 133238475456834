import React from 'react'

export default function Pagination({ table }) {
  const currentPage = table.getState().pagination.pageIndex;
  const perPage = table.getState().pagination.pageSize;
  const totalEntries = table.getFilteredRowModel().rows.length;
  const totalPage = Math.ceil(totalEntries / perPage);

  const from = ((currentPage + 1) * perPage - perPage + 1) > totalEntries ? 0 : (currentPage + 1) * perPage - perPage + 1;
  const to = (currentPage + 1) * perPage > totalEntries ? totalEntries : (currentPage + 1) * perPage;
  return (
    <div className='flex justify-between'>
      <div>
        Showing {from} to  {to} of  {totalEntries} entries
      </div>
      <div className='flex'>
        <button
          className={`rounded hover:border hover:bg-blue-500 hover:text-white py-2 px-3 text-md ${!table.getCanPreviousPage() ? 'text-gray-500 hover:bg-gray-400' : 'hover:bg-blue-500'}`}
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {"Previous"}
        </button>
        {Array(totalPage).fill(0).map((_, i) => (
          <button
            key={i}
            className={`rounded hover:border hover:bg-blue-500 hover:text-white py-2 px-3 text-md ${currentPage === i ? 'bg-blue-700 text-white' : ''}`}
            onClick={() => table.setPageIndex(i)}
            disabled={currentPage === i}
          >
            {i + 1}
          </button>
        ))}
        <button
          className={`rounded hover:border hover:text-white py-2 px-3 text-md ${!table.getCanNextPage() ? 'text-gray-500 hover:bg-gray-400' : 'hover:bg-blue-500'}`}
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {"Next"}
        </button>
        {/* BEGIN GO TO PAGE */}
        {/* <span className="flex items-center gap-1">
          <div>Page</div>
          <strong>
            {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount()}
          </strong>
        </span>
        <span className="flex items-center gap-1">
          | Go to page:
          <input
            type="number"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              table.setPageIndex(page);
            }}
            className="border p-1 rounded w-16"
          />
        </span> */}
        {/* END GO TO PAGE */}
      </div>
    </div>
  )
}
