import React from 'react'
import Search from './search';

export default function Filter({ table, globalFilter, setGlobalFilter }) {
  return (
    <div className="flex justify-between items-center">
      <div>
        <span>Show </span>
        <select
          className='rounded px-2 text-black'
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
          }}
        >
          {[10, 25, 50, 100].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </select>
        <span> entries</span>
      </div>
      <div className="w-[100%] md:w-[250px]">
        <Search
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
        />
      </div>
    </div>
  )
}
