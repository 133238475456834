import axios from "axios";
import BASE_URI from "../config/baseUri.json";

const axiosInstance = axios.create({
  baseURL: `${BASE_URI.API_GCR_BASE_URI}/kelas/`,
  retry: 1,
});

export const getUsers = async (searchTerm) => {
  const response = await axiosInstance.get(`cariuser/${encodeURI(searchTerm)}`);
  const result = await response.data;
  const hasil = result.hasil;
  const uniqueId = [...new Set(hasil.map((item) => item.id))];
  const data = uniqueId.map((id) => {
    return hasil.find((item) => item.id === id);
  });
  return data;
};

export const resetPassword = async (email) => {
  // return new Promise((resolve, reject) => {
  //   setTimeout(() => {
  //     resolve(true);
  //   }
  //   , 500);
  // });
  const response = await axiosInstance.get(`resetpassuser/${email}`);
  const result = await response.data;
  const success = !result.gagal;
  return success;
};
