import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { listUsers } from '../../services/Account';
import { FaCalendar, FaDatabase, FaEnvelope, FaSearch } from 'react-icons/fa';
import Input from '../../components/input';
import 'react-toastify/dist/ReactToastify.css';
import AccountTable from '../../components/accountTable';
import { useOutletContext } from 'react-router-dom';

export default function Account() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [license, setLicense] = useState('');
  const [lastLoginTime, setLastLoginTime] = useState('');
  const [operator, setOperator] = useState('');
  const [size, setSize] = useState('');
  const [ou, setOu] = useState('');
  const [sorting, setSorting] = useState([]);
  const [darkMode] = useOutletContext();

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['name', name, 'email', email, 'lastLoginTime', lastLoginTime, 'ou', ou, 'page', page, 'limit', limit, 'license', license, 'size', size, 'operator', operator, 'sorting', sorting?.[0]?.desc],
    queryFn: () => listUsers({name, email, lastLoginTime, ou, license, page, limit, size, sizeOperator: operator, sizeSortDesc: sorting?.[0]?.desc}),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
  }, [sorting]);
  const handleChange = (e) => {
    if (e.target.name === 'name') {
      setName(e.target.value);
    } else if (e.target.name === 'email') {
      setEmail(e.target.value);
    } else if (e.target.name === 'lastLoginTime') {
      setLastLoginTime(e.target.value);
    } else if (e.target.name === 'ou') {
      setOu(e.target.value);
    } else if (e.target.name === 'license') {
      setLicense(e.target.value);
    } else if (e.target.name === 'size') {
      setSize(e.target.value);
    } else if (e.target.name === 'operator') {
      setOperator(e.target.value);
    }
    setPage(1);
  }

  return (
    <div className='flex flex-col gap-5'>
      <div className={`${darkMode && 'bg-gray-900 bg-opacity-70'} grid grid-cols-1 md:grid-cols-2 xl:grid-cols-5 gap-4 border-2 p-5 rounded-xl`}>
        <Input
          icon={<FaSearch style={{ height: '100%' }} />}
          placeholder={'Search ...'}
          onChange={handleChange}
          value={name}
          name='name'
          title='Nama'
          darkMode={darkMode}
        />
        <Input
          icon={<FaEnvelope style={{ height: '100%' }} />}
          placeholder={'Search ...'}
          onChange={handleChange}
          value={email}
          name='email'
          title='Email'
          darkMode={darkMode}
        />
        <Input
          icon={<FaCalendar style={{ height: '100%' }} />}
          onChange={handleChange}
          value={lastLoginTime}
          title={'Terakhir Login'}
          type='date'
          name='lastLoginTime'
          darkMode={darkMode}
        />
        <div className='flex flex-col gap-1'>
            <label htmlFor='ou' className='capitalize'>OU</label>
            <select className={`min-h-[35px] h-full w-full rounded-lg border px-2 ${darkMode && 'bg-white text-black'}`} name='ou' id='ou' onChange={handleChange}>
              <option value="">Semua OU</option>
              <option value="/">untan.ac.id</option>
              <option value="/Alumni">Alumni</option>
              <option value="/Karantina">Karantina</option>
              <option value="/Organisasi Mahasiswa">Organisasi Mahasiswa</option>
              <option value="/Staff">Staff</option>
              <option value="/Staff/Ekonomi">Ekonomi</option>
              <option value="/Staff/Fahutan">Fahutan</option>
              <option value="/Staff/Faperta">Faperta</option>
              <option value="/Staff/faperta.untan.ac.id">faperta.untan.ac.id</option>
              <option value="/Staff/Fisip">Fisip</option>
              <option value="/Staff/Fkip">Fkip</option>
              <option value="/Staff/Fmipa">Fmipa</option>
              <option value="/Staff/Fmipa/fmipa.untan.ac.id">fmipa.untan.ac.id</option>
              <option value="/Staff/Fmipa/fmipa.untan.ac.id/siskom.untan.ac.id">siskom.untan.ac.id</option>
              <option value="/Staff/Hukum">Hukum</option>
              <option value="/Staff/Kedokteran">Kedokteran</option>
              <option value="/Staff/Kepegawaian">Kepegawaian</option>
              <option value="/Staff/Rumah Sakit">Rumah Sakit</option>
              <option value="/Staff/Teknik">Teknik</option>
              <option value="/Staff/Teknik/Informatika">Informatika</option>
              <option value="/Staff/Teknik/teknik.untan.ac.id">teknik.untan.ac.id</option>
              <option value="/Staff/Teknik/teknik.untan.ac.id/civil.untan.ac.id">civil.untan.ac.id</option>
              <option value="/Staff/Teknik/teknik.untan.ac.id/ee.untan.ac.id">ee.untan.ac.id</option>
              <option value="/student.untan.ac.id">student.untan.ac.id</option>
              <option value="/UPT. TIK">UPT. TIK</option>
            </select>
        </div>
        <div className='flex flex-col gap-1'>
            <label htmlFor='ou' className='capitalize'>Lisensi Plus</label>
            <select className={`min-h-[35px] h-full w-full rounded-lg border px-2 ${darkMode && 'bg-white text-black'}`} name='license' id='license' onChange={handleChange}>
              <option value="">Semua Lisensi</option>
              <option value="1">Ya</option>
              <option value="0">Tidak</option>
            </select>
        </div>
        <div className='flex flex-col gap-1'>
            <label htmlFor='operator' className='capitalize'>Operator</label>
            <select className={`min-h-[35px] h-full w-full rounded-lg border px-2 ${darkMode && 'bg-white text-black'}`} name='operator' id='operator' onChange={handleChange}>
              <option value="">Semua</option>
              <option value="$eq">=</option>
              <option value="$gte">&ge;</option>
              <option value="$gt">&gt;</option>
              <option value="$lte">&le;</option>
              <option value="$lt">&lt;</option>
            </select>
        </div>
        <Input
          icon={<FaDatabase style={{ height: '100%' }} />}
          onChange={handleChange}
          value={size}
          title={'Size'}
          placeholder={'Size'}
          type='text'
          name='size'
          darkMode={darkMode}
        />
      </div>
      <div className={`${darkMode && 'bg-gray-900 bg-opacity-70'} p-5 border-2 rounded-xl`}>
        <AccountTable
          accounts={data?.docs ?? []} 
          isLoading={isLoading}
          darkMode={darkMode}
          {...data}
          currentPage={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          refetch={refetch}
          sorting={sorting}
          setSorting={setSorting}
        />
      </div>
    </div>
  )
}
