import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";

import React, { useState } from "react";
import Pagination from "./pagination";
import Filter from "./filter";
import { ClipLoader } from "react-spinners";
import ResetButton from "../resetButton";
import { useOutletContext } from "react-router-dom";

export default function UsersTable({ data = [], isLoading }) {
  const [darkMode] = useOutletContext();
  const [globalFilter, setGlobalFilter] = useState("");
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("No", {
      cell: (info) => <div className="text-center py-1 my-2.5 px-2">{parseInt(info.row.id) + 1}</div>,
    }),
    columnHelper.accessor((row) => row.name.fullName, {
      id: "fullname",
      cell: (info) => <div className="px-2 py-1">{info.getValue()}</div>,
      header: () => <div className="py-1">Nama</div>,
    }),
    columnHelper.accessor((row) => row.primaryEmail, {
      id: "email",
      cell: (info) => <div className="px-2 py-1">{info.getValue()}</div>,
      header: () => <div className="py-1">Email</div>,
    }),
    columnHelper.accessor((row) => row.lastLoginTime, {
      id: "lastLoginTime",
      cell: (info) => <div className="px-2 py-1 text-center">{new Date(info.getValue()).toLocaleString()}</div>,
      header: () => <div className="py-1">Terakhir Login</div>,
    }),
    columnHelper.accessor((row) => row.creationTime, {
      id: "creationTime",
      cell: (info) => <div className="px-2 py-1 text-center">{new Date(info.getValue()).toLocaleString()}</div>,
      header: () => <div className="py-1">Tanggal Pendaftaran</div>,
    }),
    columnHelper.accessor("Action", {
      cell: (info) => {
        return <div className="px-2 py-1 text-center">
          <ResetButton email={info.row.original.primaryEmail} />
        </div>
      },
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <div className="space-y-2">
      <Filter
        table={table}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <table className="table-auto w-[100%] border-collapse">
        <thead 
          className={`border-collapse border-b
            ${darkMode ? 'bg-gray-900 bg-opacity-70 border-gray-500' : 'border-black'}
          `}
        >
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} className="py-2">
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {isLoading ? (
            <tr className={`border-collapse border-b ${darkMode ? 'border-gray-500' : 'border-black'}`}>
              <td className="text-center" colSpan={columns.length}>
              <div className="flex justify-center py-2 items-center">
                <ClipLoader color={darkMode ? 'white' : 'black'} />
              </div>
              </td>
            </tr>
          ) : table.getRowModel().rows.length > 0 ? (
            table.getRowModel().rows.map((row, index) => (
              <tr 
                key={row.id} 
                className={`border-collapse border-b
                  ${darkMode ? 
                    ('bg-gray-900 bg-opacity-70 border-gray-500') : 
                    (index % 2 === 1 ? 'bg-white' : 'bg-gray-200') + ' border-black'
                  }`
                }
              >
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr className={`border-collapse border-b ${darkMode ? 'border-gray-500' : 'border-black'}`}>
              <td className="text-center py-2" colSpan={columns.length}>
                Tidak ada data
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Pagination table={table} />
    </div>
  );
}
