import React, { useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth';
import { Outlet, useNavigate } from 'react-router-dom'
import auth from '../config/firebase';
import { ToastContainer } from 'react-toastify';
import Header from '../components/header';

function Layout() {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    let darkMode = localStorage.getItem('darkMode');
    if (darkMode === 'true') {
      setDarkMode(true);
    }
  }, []);

  useEffect(() => {
    if (darkMode) {
      document.querySelector('html').classList.add('dark');
    } else {
      document.querySelector('html').classList.remove('dark');
    }
    localStorage.setItem('darkMode', darkMode);
  }, [darkMode]);

  useEffect(() => {
    if (!user && !loading) {
      navigate('/');
    }
  }, [user, loading, navigate]);

  if (loading) {
    return (
      <div>
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={`${darkMode ? 'bg-gray-800 text-white' : 'bg-transparent'} h-full min-h-screen pb-5`}>
      <ToastContainer theme='colored' />
      <Header darkMode={darkMode} setDarkMode={setDarkMode} />
      <div className='w-full xl:w-11/12 px-5 xl:px-0 xl:mx-auto mt-5'>
        <Outlet context={[ darkMode ]} />
      </div>
    </div>
  )
}

export default Layout