import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { usePagination, DOTS } from "../../libs/hooks/usePagination";

const Pagination = (props) => {
  const {
    onPageChange,
    totalPage,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
    totalEntries,
    limit,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalPage,
    siblingCount,
    pageSize,
  });

  const from = (currentPage - 1) * limit + 1;
  const to = currentPage * limit;
  if (currentPage === 0) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <div className="flex justify-between">
      <span>Showing {from > totalEntries ? totalEntries : from} to {to > totalEntries ? totalEntries : to} of {totalEntries} Entries</span>
      <ul
        className={`pagination-container flex ${className}`}
      >
        <li
          className={`pagination-item px-2 flex items-center border ${currentPage === 1 ? 'disabled' : ''}`}
          onClick={() => {
            if (currentPage === 1) {
              return;
            }
            onPrevious();
          }}
        >
          <FaArrowLeft className='h-5 w-3' />
        </li>
        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return (
              <li className="pagination-item px-2.5 py-0.5 text-lg border dots" key={pageNumber + index}>
                &#8230;
              </li>
            );
          }
          return (
            <li
              key={pageNumber + index}
              className={`pagination-item px-2.5 py-0.5 text-lg border ${pageNumber === currentPage ? 'bg-blue-500 text-white' : ''}`}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </li>
          );
        })}
        <li
          className={`pagination-item flex items-center px-2.5 py-0.5 text-lg border`}
          onClick={() => {
            if (currentPage === lastPage) {
              return;
            }
            onNext();
          }}
        >
          <FaArrowRight className='h-5 w-3' />
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
