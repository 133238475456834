import React from 'react'
import { useMutation } from 'react-query';
import { ClipLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { toast } from 'react-toastify';
import { FaTrash } from 'react-icons/fa';
import { deleteUser } from '../../services/Account';

export default function DeleteUserButton({ email, refetch }) {
  const MySwal = withReactContent(Swal);
  
  const deleteUserMutation = useMutation({
    mutationKey: ["email", email],
    mutationFn: (email) => deleteUser(email),
  });

  const handleClick = () => {
    MySwal.fire({
      title: 'Are you sure?',
      text: `Delete user ${email}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUserMutation.mutate(email, {
          onSuccess: (data) => {
            if (data) {
              toast.success(`User ${email} has been deleted`);
              refetch();
            } else {
              toast.error('User delete failed');
            }
          },
          onError: (err) => {
            toast.error('User delete failed');
          },
        });
      }
    });
  }

  return (
    <button
      className="rounded-full"
      disabled={deleteUserMutation.isLoading}
      onClick={handleClick}
    >
      <div 
        className={`flex items-center py-2 3xl:py-1 px-3 rounded-lg gap-1 
        ${deleteUserMutation.isLoading ? 'bg-gray-300 text-black' : 'bg-red-600 text-white'}`}
      >
        {deleteUserMutation.isLoading && <ClipLoader size={15} />}
        {deleteUserMutation.isLoading ? 'Loading' : (
          <span className='flex items-center gap-2'>
            <FaTrash />
            <span className='hidden 3xl:block'>Delete User</span>
          </span>
        )}
      </div>
    </button>
  )
}
