import axios from "axios";
import BASEURI from "../config/baseUri.json";
import auth from "../config/firebase";

const axiosInstance = axios.create({
  baseURL: `${BASEURI.API_GCR_BASE_URI}/penggunaanakun/`,
  retry: 1,
});

export const changeOu = async (email, ou) => {
  const bearerToken = await auth?.currentUser?.accessToken;
  axiosInstance.defaults.headers.Authorization = `Bearer ${bearerToken}`;
  const response = await axiosInstance.put(`updateOrg`, {
    email,
    org: ou,
  });
  if (ou === '/Alumni') {
    await axiosInstance.post(`delete-license`, {
      email,
    });
  } else if (ou === '/student.untan.ac.id') {
    await axiosInstance.post(`assign-license`, {
      email,
    });
  }
  const result = await response.data;
  const success = !result.gagal;
  return success;
};