import React from 'react'
import { FaSearch } from 'react-icons/fa'

export default function Search({ onChange, value, className = '' }) {
  return (
    <div className='flex flex-col space-y-1 text-black'>
      <div className={`flex border rounded-sm ${className}`}>
        <span className='border-e px-3 bg-white'>
            <FaSearch style={{ height: "100%" }} />
        </span>
        <input
          type={'text'}
          value={value}
          onChange={onChange}
          placeholder={'Search...'}
          className='p-1 flex-1 focus:outline-none'
        />
      </div>
    </div>
  )
}
