import React from 'react'
import { useMutation } from 'react-query';
import { resetPassword } from '../../services/Users';
import { ClipLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { toast } from 'react-toastify';
import { FiRefreshCw } from 'react-icons/fi';

export default function ResetButton({ email }) {
  const MySwal = withReactContent(Swal);
  
  const resetPasswordMutation = useMutation({
    mutationKey: ["reset", email],
    mutationFn: (email) => resetPassword(email),
  });

  const handleClick = () => {
    MySwal.fire({
      title: 'Are you sure?',
      text: `Reset password for ${email}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, reset it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        resetPasswordMutation.mutate(email, {
          onSuccess: (data) => {
            if (data) {
              toast.success(`Password for ${email} has been reset`);
            } else {
              toast.error('Password reset failed');
            }
          },
          onError: (err) => {
            toast.error('Password reset failed');
          },
        });
      }
    });
  }

  return (
    <button
      className="rounded-full"
      disabled={resetPasswordMutation.isLoading}
      onClick={handleClick}
    >
      <div 
        className={`flex items-center py-1 px-3 rounded-lg gap-1 
        ${resetPasswordMutation.isLoading ? 'bg-gray-300 text-black' : 'bg-blue-600 text-white'}`}
      >
        {resetPasswordMutation.isLoading && <ClipLoader size={15} />}
        {resetPasswordMutation.isLoading ? 'Loading' : (
          <span className='flex items-center gap-2'>
            <FiRefreshCw />
            Reset
          </span>
        )}
      </div>
    </button>
  )
}
